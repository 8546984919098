import '../../assets/javascripts/lib/reportWebVitals';
import '../../assets/javascripts/components/tracking';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw';

import '../../assets/javascripts/components/sentry';
import '../../assets/javascripts/components/ad/dfpSnippet';
import '../../assets/javascripts/components/facebook-sdk';
import '../../assets/javascripts/components/facebook-webview';
import '../../assets/javascripts/reactjs/app';
import '../../assets/javascripts/components/appInstall';

import 'stylesheets/application-webpack.scss';
import '../../assets/javascripts/components/header-popular-keywords';
import '../../assets/javascripts/components/header-categories';

dayjs.locale('zh-tw');

if (document.getElementById('brands_show')) {
  import(
    /* webpackChunkName: "slick-slider" */ '../../assets/javascripts/components/slick-slider'
  );
}

if (document.getElementById('notification_edit'))
  import(
    /* webpackChunkName: "setting-notification-toggle" */ '../../assets/javascripts/components/settings/notificationToggle'
  );

if (document.querySelector('.selection-slider')) {
  import(
    /* webpackChunkName: "selection-slider" */ '../../assets/javascripts/components/selection-slider'
  );
}

// Toggle Product Description in brand pages
const brandProductDescriptionToggle = document.querySelectorAll(
  '.brp-item-description-toggle-link'
);
Array.from(brandProductDescriptionToggle).forEach((element) => {
  element.addEventListener('click', () => {
    element.previousElementSibling.classList.add('item-description--expanded');
    element.parentNode.removeChild(element);
  });
});

// Product Description's formats
const brandProductDescription = document.querySelectorAll(
  '.brp-item-description'
);

[].forEach.call(brandProductDescription, (el) => {
  // eslint-disable-next-line no-param-reassign
  el.textContent = el.textContent.replace(/^\s+/gm, '');
});

// calc calories
const calcCaloriesBtn = document.querySelector('.calc-calories');
if (calcCaloriesBtn) {
  const calories = document.querySelector('.calories-num-unit');
  calcCaloriesBtn.addEventListener('click', () => {
    calcCaloriesBtn.classList.add('hide');
    calories.classList.remove('hide');
  });
}

// ingredient block open btn
const ingredientOpenBtn = document.querySelector(
  '.ingredient-groups-open-btn-wrapper'
);

if (ingredientOpenBtn) {
  const ingredientsGroups = document.querySelector('.ingredients-groups');
  ingredientOpenBtn.addEventListener('click', () => {
    ingredientOpenBtn.classList.add('hide');
    ingredientsGroups.classList.remove('collapse');
  });
}

if (document.getElementById('recipes_show')) {
  import(
    /* webpackChunkName: "image-gallery" */ '../../assets/javascripts/components/image-gallery'
  );
}

if (document.querySelector('.calories-tooltip')) {
  import(
    /* webpackChunkName: "bootstrap-js-tooltip" */ 'bootstrap/js/tooltip'
  ).then(() => {
    $('.calories-tooltip').tooltip();
  });
}

if (document.getElementById('recipes_show')) {
  import(
    /* webpackChunkName: "wake-lock" */ '../../assets/javascripts/components/wake-lock.js'
  );
}
