import { logger } from '../../components/logger';

let isViewedOnRecipePage = false;

function logEventRecipePage(element: HTMLElement | null) {
  if (!element) return;

  const { experiment } = element.dataset;
  if (!experiment) return;

  const segments = new URL(window.location.href).pathname.split('/');
  const recipeId = Number(segments[segments.length - 1]);

  logger.logEvent('v2_recipe_share_install', {
    banner:
      experiment === 'control' ? 'share_install_v1-2' : 'share_install_v1-3',
    recipe_id: recipeId
  });
}

export function trackRecipePage(element: HTMLElement | null) {
  if (!element) return;

  const io = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting && !isViewedOnRecipePage) {
        logEventRecipePage(element);
        isViewedOnRecipePage = true;
        io.unobserve(element);
      }
    },
    {
      threshold: 0.5
    }
  );
  io.observe(element);
}
