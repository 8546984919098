import { LayoutShiftAttribution, LayoutShiftEntry } from './types';
import { getSelector } from './utils';

function getLargestLayoutShiftSource(sources: LayoutShiftAttribution[]) {
  return sources.reduce((a, b) => {
    return a.node &&
      a.previousRect.width * a.previousRect.height >
        b.previousRect.width * b.previousRect.height
      ? a
      : b;
  });
}

function getLargestLayoutShiftEntry(entries: LayoutShiftEntry[]) {
  return entries.reduce((a, b) => (a && a.value > b.value ? a : b));
}

function getAd2CreativeID(node: Element): string | null {
  const cidList = [
    '14695',
    '14893',
    '14890',
    '14993',
    '14979',
    '14813',
    '14928'
  ];

  if (node?.closest('.AD2M-CrazyWrap, .MediaBookAD2, .MediaBookAD2-z')) {
    if (
      document.getElementsByClassName('MediaBookAD2-ad2iUI MediaBookAD2-logo')
        .length > 0
    ) {
      const href = (
        document.getElementsByClassName(
          'MediaBookAD2-ad2iUI MediaBookAD2-logo'
        )[0] as HTMLAnchorElement
      ).href;

      if (cidList.some((cid) => href.includes(`cid=${cid}`))) return href;
      return null;
    } else if (document.getElementsByClassName('AD2M-CrazyCI').length > 0) {
      const href = (
        document.getElementsByClassName('AD2M-CrazyCI')[0] as HTMLAnchorElement
      ).href;

      if (cidList.some((cid) => href.includes(`cid=${cid}`))) return href;
      return null;
    }
  }

  return null;
}

function getCLSTarget(entries: LayoutShiftEntry[]) {
  // In some cases there won't be any entries (e.g. if CLS is 0,
  // or for LCP after a bfcache restore), so we have to check first.
  if (entries.length) {
    const largestEntry = getLargestLayoutShiftEntry(entries);
    if (largestEntry?.sources?.length) {
      const largestSource = getLargestLayoutShiftSource(largestEntry.sources);
      return (
        getAd2CreativeID(largestSource.node) || getSelector(largestSource.node)
      );
    }
  }
  // Return default/empty params in case there are no entries.
  return '(not set)';
}

function getCLSRating(cls: number) {
  if (cls < 0.1) return 'good';
  if (cls >= 0.1 && cls < 0.25) return 'ni';
  if (cls >= 0.25) return 'poor';
  return '';
}

export function getCLSDebugInfo({
  value,
  entries
}: {
  value: number;
  entries: LayoutShiftEntry[];
}) {
  return {
    cls_target: getCLSTarget(entries),
    cls_rating: getCLSRating(value)
  };
}
