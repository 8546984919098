import { NodeCursor } from './types';

function isElementNode(node: Node): node is Element {
  return node.nodeType === 1;
}

export function getSelector(currentNode: Element, maxLen = 100) {
  let sel = '';
  let node: NodeCursor = currentNode;

  try {
    while (node && isElementNode(node)) {
      const part = node.id
        ? `#${node.id}`
        : node.nodeName.toLowerCase() +
          (node.className && node.className.length
            ? `.${Array.from(node.classList.values()).join('.')}`
            : '');
      if (sel.length + part.length > maxLen - 1) return sel || part;
      sel = sel ? `${part}>${sel}` : part;
      if (node.id) break;
      node = node.parentNode as NodeCursor;
    }
  } catch (err) {
    // Do nothing...
  }
  return sel;
}
