import { triggerAlertDialog } from '@polydice/ui/dist/Dialog';
import { isFacebookApp } from 'reactjs/utils';

window.addEventListener('load', () => {
  const fileInputs = document.querySelectorAll("input[type='file']");
  if (isFacebookApp() && fileInputs.length) {
    triggerAlertDialog({
      children:
        '你目前所使用的的瀏覽器，可能會讓一些功能無法正確運作。建議你改用 Chrome 或其他瀏覽器 ，或立即下載愛料理 App ，以獲得更好的使用體驗',
      positiveButtonText: '確認'
    });
  }
});
