import { onCLS, CLSReportCallback } from 'web-vitals';
import { getCLSDebugInfo } from './CLS';
import { DebugInfo, LayoutShiftEntry } from './types';

function getDebugInfo({
  name,
  value,
  entries
}: Pick<Parameters<CLSReportCallback>[0], 'name' | 'value' | 'entries'>) {
  let debugInfo: DebugInfo | undefined;

  switch (name) {
    case 'CLS':
      debugInfo = getCLSDebugInfo({
        value,
        entries: entries as LayoutShiftEntry[]
      });
      break;
    default:
      break;
  }

  return {
    ...debugInfo,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    value: Math.round(name === 'CLS' ? value * 1000 : value)
  };
}

function gtagInit() {
  window.dataLayer = window.dataLayer || [];
  window.gtag =
    window.gtag ||
    ((...args: any[]) => {
      window.dataLayer.push(...args);
    });
}

const sendToAnalytics: CLSReportCallback = ({ name, value, entries, id }) => {
  gtagInit();

  const debugInfo = getDebugInfo({ name, value, entries });

  window.gtag('config', 'UA-18942613-4', {
    transport_type: 'beacon',
    custom_map: {
      dimension8: 'cls_target',
      dimension9: 'cls_rating'
    }
  });

  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/gtagjs
  window.gtag('event', name, {
    event_category: 'Web Vitals',
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    event_label: id,
    // Use a non-interaction event to avoid affecting bounce rate.
    non_interaction: true,
    // Custom dimensions
    ...debugInfo
  });
};

function debugLog({
  name,
  value,
  entries
}: Pick<Parameters<CLSReportCallback>[0], 'name' | 'value' | 'entries'>) {
  const debugInfo = getDebugInfo({ name, value, entries });
  /* eslint-disable no-console */
  console.log(`[${name}]`);
  console.log(debugInfo);
  /* eslint-enable no-console */
}

if (
  document.getElementById('recipes_show') ||
  document.getElementById('categories_show') ||
  document.getElementById('search_show')
) {
  if (process.env.NODE_ENV === 'development') {
    // The 2nd argument means reportAllChanges
    onCLS(debugLog);
  }
  onCLS(sendToAnalytics);
}
